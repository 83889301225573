// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-costumes-js": () => import("./../../../src/pages/costumes.js" /* webpackChunkName: "component---src-pages-costumes-js" */),
  "component---src-pages-freguesia-js": () => import("./../../../src/pages/freguesia.js" /* webpackChunkName: "component---src-pages-freguesia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institucional-js": () => import("./../../../src/pages/institucional.js" /* webpackChunkName: "component---src-pages-institucional-js" */),
  "component---src-pages-novo-requerimento-js": () => import("./../../../src/pages/novo-requerimento.js" /* webpackChunkName: "component---src-pages-novo-requerimento-js" */),
  "component---src-pages-requerimento-submetido-js": () => import("./../../../src/pages/requerimento-submetido.js" /* webpackChunkName: "component---src-pages-requerimento-submetido-js" */),
  "component---src-pages-rota-caldeirao-js": () => import("./../../../src/pages/rota-caldeirao.js" /* webpackChunkName: "component---src-pages-rota-caldeirao-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

